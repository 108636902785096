<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Goodbye!</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="msg">
            <div></div>
            <div class="text-center body-1">
              Thank you for using the application.
              <router-link to="login">Login</router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.msg {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
